import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/pengs/Projects/xijingge/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Zu, Br } from '../../components/Elements.jsx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "金刚波若波罗蜜经"
    }}>{`金刚波若波罗蜜经`}</h1>
    <p>{` `}{` `}<strong parentName="p">{`-鸠摩罗什译`}</strong></p>
    <p>{`
`}<br parentName="p"></br>{`
`}{``}</p>
    <h3 {...{
      "id": "法会因由分"
    }}>{`法会因由分`}</h3>
    <Zu yin='rú' zi='
如' mdxType="Zu" /><Zu yin='shì' zi='是' mdxType="Zu" /><Zu yin='wǒ' zi='我' mdxType="Zu" /><Zu yin='wén' zi='闻' mdxType="Zu" /><Zu yin='•' zi='•' mdxType="Zu" /><Zu yin='yī' zi='一' mdxType="Zu" /><Zu yin='shí' zi='时' mdxType="Zu" /><Zu yin='fó' zi='佛' mdxType="Zu" /><Zu yin='zài' zi='在' mdxType="Zu" /><Zu yin='shě' zi='舍' mdxType="Zu" /><Zu yin='wèi' zi='卫' mdxType="Zu" /><Zu yin='guó' zi='国' mdxType="Zu" /><Zu yin='•' zi='•' mdxType="Zu" /><Zu yin='qí' zi='祇' mdxType="Zu" /><Zu yin='shù' zi='树' mdxType="Zu" /><Zu yin='jǐ' zi='给' mdxType="Zu" /><Zu yin='gū' zi='孤' mdxType="Zu" /><Zu yin='dú' zi='独' mdxType="Zu" /><Zu yin='yuán' zi='园' mdxType="Zu" /><Zu yin='•' zi='•' mdxType="Zu" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      